var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "statistics" },
    [
      _c("headbox", {
        attrs: {
          title: "来料统计",
          isbackPath: true,
          path: "incoming",
          bgColor: "#f83637",
          iswhite: "true"
        }
      }),
      _vm.isLoading
        ? _c(
            "div",
            [
              _c(
                "center",
                { attrs: { id: "loadingbox" } },
                [_c("van-loading", { attrs: { size: "68px" } })],
                1
              )
            ],
            1
          )
        : _vm._e(),
      !_vm.isLoading
        ? _c(
            "div",
            [
              _c("el-date-picker", {
                attrs: {
                  clearable: "",
                  type: "daterange",
                  "range-separator": "至",
                  "start-placeholder": "开始日期",
                  "end-placeholder": "结束日期",
                  "value-format": "yyyy/MM/dd"
                },
                on: { change: _vm.change },
                model: {
                  value: _vm.created,
                  callback: function($$v) {
                    _vm.created = $$v
                  },
                  expression: "created"
                }
              }),
              _c(
                "div",
                { staticClass: "whiteBg" },
                [
                  _vm._m(0),
                  _vm._l(_vm.statistics.statistics, function(item, index) {
                    return _c("div", { key: index, staticClass: "flex top" }, [
                      _c("div", { staticClass: "category" }, [
                        _vm._v(_vm._s(item.type.name))
                      ]),
                      _c("div", { staticClass: "number" }, [
                        _vm._v(_vm._s(item.totalWeight))
                      ]),
                      _c("div", { staticClass: "number" }, [
                        _vm._v(_vm._s(item.afterWeight))
                      ]),
                      _c("div", { staticClass: "number" }, [
                        _vm._v(
                          _vm._s(
                            item.type.name === "黄金"
                              ? _vm.userInfo.goldIncomingFreeWeight
                              : item.type.name === "白银"
                              ? _vm.userInfo.baiyinIncomingFreeWeight
                              : item.type.name === "铂金"
                              ? _vm.userInfo.ptIncomingFreeWeight
                              : item.type.name === "钯金"
                              ? _vm.userInfo.pdIncomingFreeWeight
                              : 0
                          )
                        )
                      ]),
                      _c(
                        "div",
                        {
                          staticClass: "number",
                          staticStyle: { width: "80px" }
                        },
                        [_vm._v(" " + _vm._s(item.freeDiscountWeight) + " ")]
                      )
                    ])
                  }),
                  _vm.statistics.statistics.huangjin === undefined
                    ? _c("div", { staticClass: "flex top" }, [
                        _c("div", { staticClass: "category" }, [
                          _vm._v("黄金")
                        ]),
                        _c("div", { staticClass: "number" }, [_vm._v("0")]),
                        _c("div", { staticClass: "number" }, [_vm._v("0")]),
                        _c("div", { staticClass: "number" }, [_vm._v("0")]),
                        _c(
                          "div",
                          {
                            staticClass: "number",
                            staticStyle: { width: "80px" }
                          },
                          [_vm._v(_vm._s(_vm.userInfo.goldIncomingFreeWeight))]
                        )
                      ])
                    : _vm._e(),
                  _vm.statistics.statistics.bojin === undefined
                    ? _c("div", { staticClass: "flex top" }, [
                        _c("div", { staticClass: "category" }, [
                          _vm._v("铂金")
                        ]),
                        _c("div", { staticClass: "number" }, [_vm._v("0")]),
                        _c("div", { staticClass: "number" }, [_vm._v("0")]),
                        _c("div", { staticClass: "number" }, [_vm._v("0")]),
                        _c(
                          "div",
                          {
                            staticClass: "number",
                            staticStyle: { width: "80px" }
                          },
                          [_vm._v(_vm._s(_vm.userInfo.ptIncomingFreeWeight))]
                        )
                      ])
                    : _vm._e(),
                  _vm.statistics.statistics.bajin === undefined
                    ? _c("div", { staticClass: "flex top" }, [
                        _c("div", { staticClass: "category" }, [
                          _vm._v("钯金")
                        ]),
                        _c("div", { staticClass: "number" }, [_vm._v("0")]),
                        _c("div", { staticClass: "number" }, [_vm._v("0")]),
                        _c("div", { staticClass: "number" }, [_vm._v("0")]),
                        _c(
                          "div",
                          {
                            staticClass: "number",
                            staticStyle: { width: "80px" }
                          },
                          [_vm._v(_vm._s(_vm.userInfo.pdIncomingFreeWeight))]
                        )
                      ])
                    : _vm._e(),
                  _vm.statistics.statistics.baiyin === undefined
                    ? _c("div", { staticClass: "flex top" }, [
                        _c("div", { staticClass: "category" }, [
                          _vm._v("白银")
                        ]),
                        _c("div", { staticClass: "number" }, [_vm._v("0")]),
                        _c("div", { staticClass: "number" }, [_vm._v("0")]),
                        _c("div", { staticClass: "number" }, [_vm._v("0")]),
                        _c(
                          "div",
                          {
                            staticClass: "number",
                            staticStyle: { width: "80px" }
                          },
                          [
                            _vm._v(
                              _vm._s(_vm.userInfo.baiyinIncomingFreeWeight)
                            )
                          ]
                        )
                      ])
                    : _vm._e()
                ],
                2
              ),
              _c(
                "div",
                { staticClass: "whiteBg" },
                _vm._l(_vm.statistics.statistics, function(items, indexs) {
                  return _c(
                    "div",
                    { key: indexs, staticClass: "detailCategory" },
                    _vm._l(items.goodsStatistics, function(item, index) {
                      return _c("div", { key: index, staticClass: "detail" }, [
                        _c("div", { staticClass: "detailName" }, [
                          _vm._v(" " + _vm._s(item.goods.name) + " ")
                        ]),
                        _c("div", { staticClass: "detailWeight" }, [
                          _c("div", { staticClass: "detailNumber" }, [
                            _vm._v("来料总重: " + _vm._s(item.totalWeight))
                          ]),
                          _c("div", { staticClass: "detailNumber" }, [
                            _vm._v(
                              " 熔后总重: " +
                                _vm._s(item.totalAfterWeight) +
                                " "
                            )
                          ]),
                          _c("div", { staticClass: "detailNumber" }, [
                            _vm._v(
                              " 折足总重: " +
                                _vm._s(item.totalDiscountWeight) +
                                " "
                            )
                          ])
                        ])
                      ])
                    }),
                    0
                  )
                }),
                0
              )
            ],
            1
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "flex top" }, [
      _c("div", { staticClass: "category" }),
      _c("div", { staticClass: "title" }, [_vm._v("来料总重")]),
      _c("div", { staticClass: "title" }, [_vm._v("熔后总重")]),
      _c("div", { staticClass: "title" }, [_vm._v("折足总重")]),
      _c("div", { staticClass: "title", staticStyle: { width: "80px" } }, [
        _vm._v("剩余折足总重")
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }