<template>
  <div class="statistics">
    <headbox
      title="来料统计"
      :isbackPath="true"
      path="incoming"
      bgColor="#f83637"
      iswhite="true"
    ></headbox>
    <div v-if="isLoading">
      <center id="loadingbox">
        <van-loading size="68px" />
      </center>
    </div>
    <div v-if="!isLoading">
      <el-date-picker
        clearable
        v-model="created"
        @change="change"
        type="daterange"
        range-separator="至"
        start-placeholder="开始日期"
        end-placeholder="结束日期"
        value-format="yyyy/MM/dd"
      >
      </el-date-picker>
      <div class="whiteBg">
        <div class="flex top">
          <div class="category"></div>
          <div class="title">来料总重</div>
          <div class="title">熔后总重</div>
          <div class="title">折足总重</div>
          <div class="title" style="width: 80px">剩余折足总重</div>
        </div>
        <div
          class="flex top"
          v-for="(item, index) in statistics.statistics"
          :key="index"
        >
          <div class="category">{{ item.type.name }}</div>
          <div class="number">{{ item.totalWeight }}</div>
          <div class="number">{{ item.afterWeight }}</div>
          <div class="number">{{ item.type.name === '黄金' ? userInfo.goldIncomingFreeWeight : item.type.name === '白银' ? userInfo.baiyinIncomingFreeWeight : item.type.name === '铂金' ? userInfo.ptIncomingFreeWeight : item.type.name === '钯金' ? userInfo.pdIncomingFreeWeight : 0 }}</div>
          <div class="number" style="width: 80px">
            {{ item.freeDiscountWeight }}
          </div>
        </div>
        <div
          class="flex top"
          v-if="statistics.statistics.huangjin === undefined"
        >
          <div class="category">黄金</div>
          <div class="number">0</div>
          <div class="number">0</div>
          <div class="number">0</div>
          <div class="number" style="width: 80px">{{ userInfo.goldIncomingFreeWeight }}</div>
        </div>
        <div class="flex top" v-if="statistics.statistics.bojin === undefined">
          <div class="category">铂金</div>
          <div class="number">0</div>
          <div class="number">0</div>
          <div class="number">0</div>
          <div class="number" style="width: 80px">{{ userInfo.ptIncomingFreeWeight }}</div>
        </div>
        <div class="flex top" v-if="statistics.statistics.bajin === undefined">
          <div class="category">钯金</div>
          <div class="number">0</div>
          <div class="number">0</div>
          <div class="number">0</div>
          <div class="number" style="width: 80px">{{ userInfo.pdIncomingFreeWeight }}</div>
        </div>
        <div class="flex top" v-if="statistics.statistics.baiyin === undefined">
          <div class="category">白银</div>
          <div class="number">0</div>
          <div class="number">0</div>
          <div class="number">0</div>
          <div class="number" style="width: 80px">{{ userInfo.baiyinIncomingFreeWeight }}</div>
        </div>
      </div>
      <div class="whiteBg">
        <div
          v-for="(items, indexs) in statistics.statistics"
          :key="indexs"
          class="detailCategory"
        >
          <div
            class="detail"
            v-for="(item, index) in items.goodsStatistics"
            :key="index"
          >
            <div class="detailName">
              {{ item.goods.name }}
            </div>
            <div class="detailWeight">
              <div class="detailNumber">来料总重: {{ item.totalWeight }}</div>
              <div class="detailNumber">
                熔后总重: {{ item.totalAfterWeight }}
              </div>
              <div class="detailNumber">
                折足总重: {{ item.totalDiscountWeight }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import headbox from "@/components/head.vue";
import { incomingStatistics } from "@/utils/api";

export default {
  data() {
    return {
      created: [new Date().toLocaleDateString().replace(/\//g, '/'), new Date().toLocaleDateString().replace(/\//g, '/')],
      isLoading: true, //加载状态
      userInfo: {}, // 用户信息,
      statistics: {} // 静态数据
    };
  },
  components: {
    headbox
  },
  mounted() {
    this.userInfo = JSON.parse(localStorage.getItem("userinfo"));
    const params = { created: { start: new Date(this.created[0] + ' 00:00:00'), end: new Date(this.created[1] + ' 23:59:59') } };
    incomingStatistics(params).then(res => {
      this.statistics = res;
      this.isLoading = false;
/*      setTimeout(() => {
        const ElRangeCloseIcon = document.getElementsByClassName(
          "el-range__close-icon"
        )[0];
        ElRangeCloseIcon.classList.add("el-icon-close")
      }, 500);*/
    });
  },
  methods: {
    change(e) {
      let params = undefined;
      if (e) {
        params = { created: { start: new Date(e[0] + ' 00:00:00'), end: new Date(e[1] + ' 23:59:59') } };
      } else {
        params = {};
      }
      incomingStatistics(params).then(res => {
        this.statistics = res;
      });
    },
  }
};
</script>

<style lang="scss" scoped="true">
.statistics {
  background: #f5f5f5;
  width: 100%;
  height: 100%;
}
::v-deep {
  .el-date-editor {
    margin: 20px 30px;
    width: 690px;
    height: 50px;
  }
  .el-date-editor.is-active {
    border-color: #ff2d2e !important;
  }
  .el-range-input {
    font-size: 28px;
    width: 43%;
  }
  .el-input__icon {
    font-size: 28px;
    line-height: 45px;
  }
  .el-range-separator {
    font-size: 28px;
    line-height: 40px;
  }
  /*  .el-input__icon:nth-child(2) {
    display: none !important;
  }*/
}
.whiteBg {
  margin: 24px;
  padding: 0 24px;
  background: #ffffff;
  font-size: 24px;
  color: #333333;
  .total {
    padding-top: 20px;
    font-weight: 600;
    color: #333333;
    font-size: 28px;
  }
  .top {
    padding-top: 32px;
    text-align: center;
    .category {
      width: 68px;
    }
    .title {
      width: 100px;
      color: #919191;
      margin-left: 30px;
    }
    .number {
      width: 100px;
      margin-left: 30px;
    }
    &:last-child {
      padding-bottom: 32px;
    }
  }
  .bottom {
    font-size: 28px;
    padding-top: 24px;
    &:first-child {
      padding-top: 32px;
    }
    &:last-child {
      padding-bottom: 32px;
    }
  }

  .detail {
    .detailName {
      padding-top: 20px;
      font-weight: 600;
      color: #333333;
      font-size: 28px;
    }
    .detailWeight {
      text-align: center;
      padding-top: 32px;
      display: flex;
      justify-content: space-between;
    }
    .detailNumber {
      width: 218px;
    }
    &:last-child {
      padding-bottom: 32px;
    }
  }
  .detailCategory {
    border-bottom: 1px solid #cccccc;
    &:last-child {
      border-bottom: none;
    }
  }
}
</style>
